import { Box, Button, Container, TextField } from "@material-ui/core"
import firebase from "firebase";
import { useState } from "react";

const LogInScreen = () => {

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    return (
        <Container maxWidth="sm" >
            <Box display="flex" justifyContent="center">
                <TextField id="standard-basic" label="email" value={email} onChange={(event) => {
                    setEmail(event.target.value);
                }} />
            </Box>
            <Box display="flex" justifyContent="center">
                <TextField id="standard-basic" label="password"
                    type="password"
                    autoComplete="current-password"
                    value={password} onChange={(event) => {
                        setPassword(event.target.value);
                    }} />
            </Box>
            <Box style={{ margin: "10px" }} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" onClick={() => {
                    console.log(`login with email`);
                    firebase.auth().signInWithEmailAndPassword(email, password)
                        .then((userCredential) => {
                            const user = userCredential.user;
                            console.log(`user is ${user}`);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }}>
                    LogIn
                </Button>
            </Box>
        </Container >
    )
}

export default LogInScreen