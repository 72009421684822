import { Box, List, ListItem, Paper } from "@material-ui/core";
import firebase from "firebase";
import { useCallback, useEffect, useState } from "react";


interface VideoItem {
    key: string;
    name?: string;
}


const UploadedVideoList = () => {
    const [refItems, setListItems] = useState<VideoItem[]>([]);
    const storageRef = firebase.storage().ref();
    const listRef = storageRef.child('video');


    const fetchVideoKeys = useCallback(async () => {
        const fileList = await listRef.listAll();
        const videos: VideoItem[] = await Promise.all(fileList.prefixes.map(async (key) => {
            const files = await key.listAll();
            return { key: key.name, name: files.items[0].name } as VideoItem
        }));
        setListItems(videos);
    }, [listRef]);

    useEffect(() => {
        fetchVideoKeys();
    }, [fetchVideoKeys]);


    const listItems = refItems?.map((item: VideoItem) => {
        return (
            <ListItem key={item.key}>
                <Box>
                    {`Video ${item.name}  id: ${item.key}  `}
                </Box>
            </ListItem>
        );
    });
    return (
        <Paper>
            <List>
                {listItems}
            </List>
        </Paper>
    );
};
export default UploadedVideoList;