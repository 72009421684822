import UploadedVideoList from "../components/UploadedVideoList";
import UploadVideo from "../components/UploadVideo"


const VideoStorageScreen = () => {
    return (
        <div>
            <UploadVideo />
            <UploadedVideoList />
        </div>
    )
};

export default VideoStorageScreen;