import { Container } from "@material-ui/core";

const Playlists = () => {

    return (
        <Container>
            Playlists
        </Container>
    );
};

export default Playlists;