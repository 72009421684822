import { Box, Button, Container } from "@material-ui/core"
import DevicesStatus from "../screens/DevicesStatus";
import Playlists from "../screens/Playlists";
import VideoStorageScreen from "../screens/VideoStorageScreen";


interface AppMenuListener {
    onShow: (screen: JSX.Element) => void;
}


const AppMenu = (callback: AppMenuListener) => {
    return (
        <Container>
            <Box>
                <Button onClick={() => {
                    callback.onShow(<DevicesStatus />);
                }}>Devices
                </Button>
            </Box>
            <Box>
                <Button onClick={() => {
                    callback.onShow(<VideoStorageScreen />);
                }}>Videos
                </Button>
            </Box>
            <Box>
                <Button onClick={() => {
                    callback.onShow(<Playlists />);
                }}>Playlists
                </Button>
            </Box>
        </Container>
    );

};

export default AppMenu;