import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { firebaseConfig } from './data/config';
import LoginScreen from './screens/LoginScreen';
import MainScreen from './screens/MainScreen';

const App = () => {

  const [content, setContent] = useState<JSX.Element>(<LoginScreen />);
  console.log("App init");

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setContent(<MainScreen />);
      } else {
        setContent(<LoginScreen />);
      }
    });
  }, []);
  return (<div>{content}</div>);
}

export default App;
