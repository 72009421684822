import { Container } from "@material-ui/core";

const DevicesStatus = () => {

    return (
        <Container>
            Devices
        </Container>
    );
};

export default DevicesStatus;