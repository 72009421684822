import { Box, Button, Container, Paper } from "@material-ui/core";
import firebase from "firebase";
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';


const UploadVideo = () => {

    const [selectedFile, setSelectedFile] = useState<File>();
    const [uploadButtonEnabled, setUploadButtonEnabled] = useState(false);
    const [uploadingStatus, setUploadingStatus] = useState<string>('');

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setUploadButtonEnabled(true);
    };

    const storageRef = firebase.storage().ref();

    return (<Container>
        <Paper style={{ margin: '8px', padding: '8px' }}>
            <Box>
                <input type="file" name="file" onChange={changeHandler} />
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!uploadButtonEnabled}
                    onClick={() => {
                        const uploadTask = storageRef.child(`video/${uuidv4()}/${selectedFile?.name}`).put(selectedFile!!);
                        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED:
                                        setUploadingStatus('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING:
                                        setUploadingStatus(`Uploading ${progress}`);
                                        break;
                                    case firebase.storage.TaskState.SUCCESS:
                                        setUploadingStatus("Done");
                                        break;
                                }
                            },
                            (error) => {
                                setUploadingStatus(error.message);
                            },
                            () => {
                                setUploadButtonEnabled(false);
                                setUploadingStatus("Done");
                            },
                        );
                    }}
                >Upload video</Button>
                <div>{uploadingStatus}</div>
            </Box>
        </Paper>
    </Container>);
};

export default UploadVideo;