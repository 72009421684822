import { AppBar, Button, Grid, Paper, Toolbar, Typography } from "@material-ui/core";
import firebase from "firebase";
import { useState } from "react";
import AppMenu from "../components/AppMenu";
import VideoStorageScreen from "./VideoStorageScreen";

const MainScreen = () => {

    const [activeScreen, setActiveScreen] = useState<JSX.Element>(<VideoStorageScreen />);

    return (
        <div>
            <AppBar position="static" style={{ flexGrow: 1 }} >
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }} >
                        Carbord Cloud
                    </Typography>
                    <Button color="inherit" onClick={() => {
                        firebase.auth().signOut();
                    }}>Logout</Button>
                </Toolbar>
            </AppBar>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    < AppMenu onShow={(element) => {
                        setActiveScreen(element);
                    }} />
                </Grid>
                <Grid item xs={10}>
                    {activeScreen}
                </Grid>
            </Grid>
        </div >
    );
}

export default MainScreen;